import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { MainUrl, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import ContactUS from '../../ContactUS';
// import ForgetPasswordComponent from './ForgetPassword';

export default function Dashboard() {
	const [userName, setUserName] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [PhoneNumber, setPhoneNumer] = React.useState("")

	const [reload, setReload] = React.useState(false)
	const [passwordShow, setPasswordShow] = React.useState(false)
	const [AlertShow, setAlertShow] = React.useState(false)
	const [Alertcomp, setAlertcomp] = React.useState("")
	const [Register, setRegister] = React.useState(false)
	const [loginSuccess, setLoginSuccess] = React.useState(false)
	const [forgetPasswordPressed, setForgetPasswordPressed] = React.useState(false)


	return (
		<div style={{ backgroundColor: "#f2f3f2", minHeight: "100vh",padding:"10px", overflow: 'hidden' }}>
			<div className='row'>
				<div className="row">
					<div style={{ backgroundColor: "#fff",marginTop:"3vh" }}>
						<div>
							<div style={{ display: 'flex', justifyContent: 'center', padding: "0 0 50px" }}>
								<img
									src={signInImage}
									height={"80px"}
								/>
							</div>
							<div className='row'>
								<div className="col-6">
									<Link className="Link" to={slugs.demo_exam}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px" }}>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/5231719.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Demo Exam</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>भैसेपाटीमा जस्तै UBT परिक्षा freeमा अभ्यास गर्न </div>
										</div>
									</Link>
								</div>
								<div className="col-6">
									<Link className="Link" to={slugs.classroom}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px" }}>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/3475156.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Class Room</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>Institute को विद्यार्थीका लागि  भैंसेपाटीमा जस्तै UBT परिक्षा दिन </div>
											</div>
									</Link>
								</div>


							</div>
							<div className='row mt-5'>
								<div className="col-6">
									<a className="Link" href={MainUrl}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px" }}>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/home.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Home</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>गृह पृष्टमा हाम्रो institute को बारेमा जानकारी लिन</div>
										
										</div>
									</a>
								</div>
								<div className="col-6">
									<Link className="Link" to={slugs.daily_exam}>
										<div className='shadow center' style={{ cursor: "pointer", padding: "5px" }}>
											<img src='https://api.aimsintegrationeducation.com.np/wp-content/uploads/2023/07/login.png'
												style={{ height: "50px", width: "50px" }}
											/>
											<br />
											<div style={{ fontSize: "17px", fontWeight: "bold", margin: "10px" }}>Exam</div>
											<div style={{ fontSize: "10px", margin: "10px" }}>घरमै बसेर UBT परिक्षा दिन भैसीपाटीमा जस्तै UBT परिक्षा Format मा</div>
										
										</div>
									</Link>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div >
			{/* <ContactUS/> */}
			{
				reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}

		</div >
	)
}